import { render, staticRenderFns } from "./DepositStep2.vue?vue&type=template&id=5b76aa92&scoped=true&"
import script from "./DepositStep2.vue?vue&type=script&lang=js&"
export * from "./DepositStep2.vue?vue&type=script&lang=js&"
import style0 from "./DepositStep2.vue?vue&type=style&index=0&id=5b76aa92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b76aa92",
  null
  
)

export default component.exports